import React from 'react';
import logo from './assets/logo.png';
import PropTypes from 'prop-types';
import Routes from "./Routes";
import { Link, useLocation, withRouter } from "react-router-dom";
import './assets/cherp.css';
import {loggedIn, requireAuth} from './auth.js';
import axios from 'axios';
import Cookies from 'js-cookie';
import Nav from './components/Nav.js';

class App extends React.Component {
  

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  componentDidMount() {

  }

  unreadCountCall() {

  }

  render() {
    let arr = ["What if your mum ran on batteries?", "Fart fetish Dave", "Pee Pee Poo Poo Man"];
    let quote = arr[Math.floor(Math.random() * arr.length)];
    if (loggedIn() == true) {
      return (
      <div>
      <header><h1><a href="/"><img src={logo}/></a></h1></header>
      <Nav />
      <Routes />
      <footer></footer>
      </div>
      );
    } else {
    return (
      <div>
      <header><h1><a href="/"><img src={logo}/></a></h1></header>
      <Routes />
      <footer></footer>
      </div>
    );
  }
}
}

export default withRouter(App);
