import React from 'react';
import Cookies from 'js-cookie';

    export function loggedIn() {
        let sessionID = Cookies.get('cherpsession');
        if (sessionID) {
            return true;
        } else {
            return false;
        }
    }
  
    export function requireAuth(nextState, replace) {
        if (!loggedIn()) {
            replace({
                pathname: '/'
            })
        } else {
            console.log('Logged in');
        }
    }
  
