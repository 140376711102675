import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    Redirect,
    withRouter
} from "react-router-dom";
import ReactMarkdown, { components } from 'react-markdown';
import gfm from 'remark-gfm';

class FullMark extends React.Component {
    render() {
        return(
            <ReactMarkdown remarkPlugins={[gfm]} {...this.props} />
        )
    }
}

class ChatMark extends React.Component {
    render() {
        const linkCon = (props) => {return <a href={props.href}>{props.text}</a>};
        const customRenders = {
            //auto-load image -> link
            "img": (props) => 
            {
                const msg = "embed-disabled"; //DO NOT REPLACE WITH REAL ALT TEXT.
                return React.createElement(linkCon, {href: props.src, text: msg}, null)
            }
        };
        return(
            //transformImageUri={(uri) => "embed-disabled"}
            <ReactMarkdown remarkPlugins={[gfm]} {...this.props} components={customRenders} />
        )
    }
}

export const FullMarkdown = withRouter(FullMark);
export const ChatMarkdown = withRouter(ChatMark);