import React, {Suspense, lazy} from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {loggedIn, requireAuth} from './auth.js';
import Logout from "@Routes/Logout.js";

const Home = lazy(() => import("@Routes/Home.js"));

const ChatHandler = lazy(() => import("@Routes/ChatHandler.js"));
const DirectoryHandler = lazy(() => import("@Routes/DirectoryHandler.js"));
const ProfileHandler = lazy(() => import("@Routes/ProfileHandler.js"));
const Donate = lazy(() => import("@Routes/Donate.js"));
const Account = lazy(() => import("@Routes/Account.js"));
const Banned = lazy(() => import("@Routes/Banned.js"));
const UserInspect = lazy(() => import("@Routes/UserInspect.js"));
const Use = lazy(() => import("@Routes/Use.js"));
const Admin = lazy(() => import('@Routes/Admin.js'));
const AdminAds = lazy(() => import('@Routes/AdminAds.js'));
const AdEdit = lazy(() => import('@Routes/AdEdit.js'));
const Verify = lazy(() => import('@Routes/Verify.js'));


export default function Routes() {
    let isLoggedIn = loggedIn();
    const loadComp = 
    <main class="flex">
        <div class="tile2">
            <h4>
                Loading...
            </h4>
            <p>Please wait for your page to load.</p>
        </div>
    </main>;
    return(
        <Suspense fallback={loadComp}>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route path="/chats">
                    <ChatHandler />
                </Route>
                <Route path="/directory">
                    <DirectoryHandler />
                </Route>
                <Route path="/profile">
                    <ProfileHandler />;
                </Route>
                <Route exact path="/banned" onEnter={requireAuth}>
                    <Banned />
                </Route>
                <Route exact path="/user/inspect/:userName">
                    <UserInspect />
                </Route>
                <Route exact path="/admin">
                    <Admin />
                </Route>
                <Route exact path="/admin/adverts">
                    <AdminAds />
                </Route>
                <Route exact path="/admin/adverts/edit/:adID">
                    <AdEdit />
                </Route>
                <Route exact path="/use/:page">
                    <Use />
                </Route>
                <Route exact path="/donate">
                    <Donate />
                </Route>
                <Route exact path="/logout">
                    <Logout />
                </Route>
                <Route exact path="/account">
                    <Account />
                </Route>
                <Route exact path="/verify">
                    <Verify />
                </Route>
            </Switch>
        </Suspense>
    )
}