import React, {useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    Redirect,
    withRouter
} from "react-router-dom";
import PropTypes from 'prop-types';
import {ChatMarkdown} from './CherpMarkdown';
import moment from 'moment';
import ExpandCollapse from 'react-expand-collapse';


class MiniChat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chatData: props.chatData,
        };

    }

    componentDidMount() {

    }

    render() {
        let subtitle = '';
        let createTime = moment.utc(this.state.chatData.created).local().format('YYYY-MM-DD HH:mm:ss');
        let updateTime = moment.utc(this.state.chatData.updated).local().format('YYYY-MM-DD HH:mm:ss');
        let chatTitle = '';
        let chatDescription = '';
        if (this.state.chatData.chatDescription != undefined && this.state.chatData.chatDescription != '') {
            chatDescription = this.state.chatData.chatDescription;
        } else {
            chatDescription = this.state.chatData.chatMessage.text;
        }
        if (this.state.chatData.chatTitle != undefined && this.state.chatData.chatTitle != '') {
            chatTitle = this.state.chatData.chatTitle;
        } else {
            chatTitle = this.state.chatData.chatURL;
        }
        if (this.state.chatData.ongoing === true) {
            subtitle = 'Ongoing. ';
        } else {
            subtitle = 'Ended. ';
        }
        subtitle = subtitle + 'Started ' + createTime + ', Updated ' + updateTime;
        return (
        <li class={"tile2 " + "chat " + this.state.chatData.status}>
            <h3><a href={"/chats/" + this.state.chatData.chatURL}>{chatTitle} 
            {this.state.chatData.status != 'answered' && 
                <span> ({this.state.chatData.status})</span>}</a></h3>
            <p class="subtitle">{subtitle}<br />
            {this.state.chatData.fromPrompt != 0 &&
                <span>From prompt <a href={"/directory/view/" + this.state.chatData.fromPrompt}>{this.state.chatData.fromPrompt}</a></span>
            }
            </p>
            <div class="collapsed_content message" style={{color: this.state.chatData.chatMessage.colour}}>
            <ExpandCollapse previewHeight="88px">
                            <ChatMarkdown children={chatDescription} />
                            </ExpandCollapse>

            </div>
            <hr />
            <div class="actions">
                <div class="right">
                    <a href={"/chats/info/" + this.state.chatData.chatURL}>Edit</a>
                </div>
            </div>
        </li>
        );
    }

}

export default withRouter(MiniChat);