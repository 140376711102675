import React, {useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    withRouter,
    Redirect
} from "react-router-dom";
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        Object.keys(Cookies.get()).forEach(function(cookieName) {
            var neededAttributes = {
              
            };
            Cookies.remove(cookieName, neededAttributes);
          });
        window.localStorage.removeItem('isAdmin');
    }

    render() {
        Object.keys(Cookies.get()).forEach(function(cookieName) {
            var neededAttributes = {
              domain: process.env.REACT_APP_LOGOUT_URL
            };
            Cookies.remove(cookieName, neededAttributes);
            });
            window.localStorage.removeItem('isAdmin');
            window.location = window.location.origin;
            return null;
    }
}

export default withRouter(Logout);