import React, {useState, useEffect} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    withRouter,
    Redirect
} from "react-router-dom";
import PropTypes from 'prop-types';
import MiniChat from '../components/MiniChat.js';
import {loggedIn, requireAuth} from '../auth.js';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isAdmin: false,
            count: 0,
            verified: false
        };
    }

    componentDidMount() {
        if (loggedIn()) {
            if (window.localStorage.getItem('isAdmin') != null) {
                if (JSON.parse(window.localStorage.getItem('isAdmin')) === true) {
                    this.setState({isAdmin: true, isLoaded: true});
                } else {
                    this.setState({isAdmin: false, isLoaded: true});
                }
            } 
            fetch(process.env.REACT_APP_API_URL + 'user/type', {
                method: 'get',
                mode: 'cors',
                credentials: 'include'
            })
            .then(res => {
                if (!res.ok) {
                    this.setState({error: res.status + ' ' + res.text});
                    throw new Error(res.status + ' ' + res.text);
                }
                return res.json();
            })
            .then(
            (result) => {
                if (result.userStatus == "admin") {
                    window.localStorage.setItem('isAdmin', true);
                    this.setState({isAdmin: true, isLoaded: true});
                } else {
                    window.localStorage.setItem('isAdmin', false);
                    this.setState({isAdmin: false, isLoaded: true});
                }
            });
        }
        this.timer = setInterval(()=> this.apiCall(), 180000);
        this.apiCall();
    }

    componentWillUnmount() {
        this.timer = null;
    }

    apiCall() {
        fetch(process.env.REACT_APP_API_URL + 'user/unreadcount', {
            method: 'get',
            mode: 'cors',
            credentials: 'include'
            })
            .then(res => {
                if (!res.ok) {
                    this.setState({error: res.status + ' ' + res.text});
                    throw new Error(res.status + ' ' + res.text);
                }
                return res.json();
            }) 
            .then(
            (result) => {
                this.setState({
                isLoaded: true,
                count: result.count,
                verified: result.verified
                });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                isLoaded: true,
                error
                });
            }
            )
        }

    render() {
        let username = Cookies.get('cherpusername');
        let chats = '';
        let dataUnread = '';
        if (this.state.count != 0) {
            chats = '(' +this.state.count + ' unread)';
            dataUnread = this.state.count;
        }
        return(
        <nav id="nav">
            <ul>
            <li><a id="nav_home" href="/">Home</a></li>
            <li><a id="nav_directory" href="/directory">Directory</a></li>
            <li><a id="nav_chat_list" data-unread={dataUnread} href="/chats/all">Your Chats {chats}</a></li>
            <li><a id="nav_account" href="/account">{username}
            {this.state.verified &&
            <FontAwesomeIcon className="verifiedCheck" icon={faCheckCircle} />
            }
            </a></li>
            {this.state.isAdmin &&
            <li><a id="nav_admin" href="/admin">Admin</a></li>
            }
            <li><a id="nav_donate" href="/donate">Donate</a></li>
            <li><a id="nav_use" href="/use/rules">Site Use</a></li>
            <li><a id="nav_discord" href="https://discord.gg/mQDrXeN">Discord</a></li>
            </ul>
        </nav>
        );
    }
}

export default withRouter(Nav);